import KbArticleUrls from 'client/util/Constants/KbArticleUrls';

const MdmRequirementsTagConsts = {
  endUserInteraction: {
    text: 'end user interaction with admin permissions for macOS 11.0+',
    link: KbArticleUrls.mdmCustomPolicy,
  },
  macOS: {
    text: 'JumpCloud MDM enrollment for macOS 11.0+',
    link: KbArticleUrls.preparingmacosBigSur,
  },
  windowOS: {
    text: 'JumpCloud MDM enrollment',
    link: KbArticleUrls.windowMdmKB,
  },
  mdm: {
    text: 'JumpCloud MDM enrollment',
    link: KbArticleUrls.deployMdmCustomConfigurationProfile,
  },
  iOSMdm: {
    text: 'JumpCloud MDM enrollment',
    link: KbArticleUrls.creatingIOSPolicies,
  },
  approvedMdm: {
    text: 'JumpCloud User Approved MDM enrollment',
    link: KbArticleUrls.mdmCustomPolicy,
  },
  userEnrollmentMdm: {
    text: 'JumpCloud MDM User Enrollment',
    link: KbArticleUrls.creatingIOSPolicies,
  },
  installVentura: {
    text: 'Does not block macOS Ventura Installation on macOS Monterey 12.3 and later',
    link: KbArticleUrls.macVenturaInstallation,
  },
};

export const DarwinPoliciesNamesConsts = {
  allowActivationLock: {
    name: 'activation_lock_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  appAccess: {
    name: 'application_access_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  appStore: {
    name: 'app_store_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  automaticMacOsUpdates: {
    name: 'automatic_macOS_updates_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  blockBigSurInstall: {
    name: 'block_macos_big_sur_install_darwin',
  },
  camera: {
    name: 'camera_control_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  customMDM: {
    name: 'custom_mdm_profile_darwin',
    requirement: MdmRequirementsTagConsts.mdm,
  },
  dictation: {
    name: 'dictation_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  disableDisc: {
    name: 'disable_disc_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  disableExternalDisk: {
    name: 'disable_external_disk_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  disableGuest: {
    name: 'disable_guest_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  echo: {
    name: 'echo_darwin',
  },
  enrollment: {
    name: 'mdm_darwin',
    requirement: MdmRequirementsTagConsts.endUserInteraction,
  },
  exampleUI: {
    name: 'example_ui_elements_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  fileVault: {
    name: 'filevault-2_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  firewall: {
    name: 'local_firewall_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  gatekeeper: {
    name: 'gatekeeper_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  icloud: {
    name: 'icloud_access_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  itunes: {
    name: 'itunes_music_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  jcApp: {
    name: 'jumpcloud_app_controls_darwin',
  },
  lockScreen: {
    name: 'lock_screen_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  loginText: {
    name: 'login_window_text_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  loginWindow: {
    name: 'login_window_control_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  macAnalytics: {
    name: 'mac_analytics_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  password: {
    name: 'password_modification_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  power: {
    name: 'power_controls_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  removableStorage: {
    name: 'removable_storage_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  disableSiriDarwin: {
    name: 'disable_siri_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  screenRecording: {
    name: 'screen_recording_pppc_darwin',
    requirement: MdmRequirementsTagConsts.approvedMdm,
  },
  spotlightDarwin: {
    name: 'spotlight_internet_results_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  systemPreferences: {
    name: 'system_preferences_panes_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  systemUnlock: {
    name: 'system_unlock_control_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  systemUpdate: {
    name: 'system_update_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  wallpaper: {
    name: 'wallpaper_modification_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  appNotificationsdarwin: {
    name: 'app_notifications_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  restrictEACS: {
    name: 'restrictEACS_control_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  ntpTimeServer: {
    name: 'ntp_server_policy_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  disableiCloud: {
    name: 'disable_icloud_private_relay_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  crowdStrikeFalcon: {
    name: 'crowdStrike_Falcon_MDM_Settings_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  crowdStrikeFalconFirmware: {
    name: 'crowdStrike_Falcon_Firmware_Analysis_MDM_Settings_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  applicationPrivacyPreferences: {
    name: 'application_privacy_preferences_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  kernelExtension: {
    name: 'kernel_extension_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  systemExtension: {
    name: 'system_extension_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  encryptedDnsHTTPS: {
    name: 'encrypted_dns_https_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  encryptedDnsTLS: {
    name: 'encrypted_dns_tls_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  restrictUnlockBiometrics: {
    name: 'disable_unlock_with_biometrics_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  watchUnlock: {
    name: 'disable_unlock_with_apple_watch_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  certificateInstallation: {
    name: 'certificates_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  airPrintPolicy: {
    name: 'airprint_policy_darwin',
    requirement: MdmRequirementsTagConsts.mdm,
  },
  customFontPolicy: {
    name: 'custom_font_policy_darwin',
    requirement: MdmRequirementsTagConsts.mdm,
  },
  wifiPolicy: {
    name: 'wifi_configuration_darwin',
    requirement: MdmRequirementsTagConsts.mdm,
  },
  sentinelOne: {
    name: 'sentinelOne_permission_darwin',
    requirement: MdmRequirementsTagConsts.mdm,
  },
  blockInstallProfile: {
    name: 'block_install_profile_darwin',
    requirement: MdmRequirementsTagConsts.mdm,
  },
  delaySoftwareUpdate: {
    name: 'delay_software_updates_darwin',
    requirement: MdmRequirementsTagConsts.mdm,
  },
  allowLoginItem: {
    name: 'manage_login_items_darwin',
    requirement: MdmRequirementsTagConsts.mdm,
  },
  disableContentCaching: {
    name: 'disable_content_caching_darwin',
    requirement: MdmRequirementsTagConsts.mdm,
  },
  disablePasswordAutofill: {
    name: 'disable_password_autofill_darwin',
    requirement: MdmRequirementsTagConsts.mdm,
  },
  manageRapidSecurityResponse: {
    name: 'manage_rapid_security_response_darwin',
    requirement: MdmRequirementsTagConsts.mdm,
  },
  enforcedFingerprintTimeout: {
    name: 'enforced_fingerprint_timeout_darwin',
    requirement: MdmRequirementsTagConsts.mdm,
  },
  disablePasswordSharing: {
    name: 'disable_password_sharing_darwin',
    requirement: MdmRequirementsTagConsts.mdm,
  },
  disableAirDrop: {
    name: 'disable_airdrop_darwin',
    requirement: MdmRequirementsTagConsts.mdm,
  },
  disableAirPlay: {
    name: 'disable_airplay_darwin',
    requirement: MdmRequirementsTagConsts.mdm,
  },
  disableUSBRestrictedMode: {
    name: 'disable_usb_restricted_mode_darwin',
    requirement: MdmRequirementsTagConsts.mdm,
  },
  httpProxy: {
    name: 'http_proxy_darwin',
    requirement: MdmRequirementsTagConsts.mdm,
  },
  energySettings: {
    name: 'energy_settings_darwin',
    requirement: MdmRequirementsTagConsts.mdm,
  },
  malwarebytePreferences: {
    name: 'malwarebytes_privacy_preference_darwin',
    requirement: MdmRequirementsTagConsts.mdm,
  },
  blockMacVenturaInstall: {
    name: 'block_macos_ventura_app_install_darwin',
    requirement: MdmRequirementsTagConsts.installVentura,
  },
  dockPolicy: {
    name: 'dock_policy_darwin',
    requirement: MdmRequirementsTagConsts.mdm,
  },
  scep: {
    name: 'scep_profiles_darwin',
    requirement: MdmRequirementsTagConsts.mdm,
  },
  softwareUpdatePreferences: {
    name: 'software_update_preferences_darwin',
    requirement: MdmRequirementsTagConsts.mdm,
  },
  contentCaching: {
    name: 'content_caching_darwin',
    requirement: MdmRequirementsTagConsts.mdm,
  },
  chromeExtensionPolicy: {
    name: 'google_chrome_browser_force_installed_extension_list_darwin',
    requirement: MdmRequirementsTagConsts.mdm,
  },
  systemSettings: {
    name: 'system_settings_policy_darwin',
    requirement: MdmRequirementsTagConsts.mdm,
  },
  blockVeturaInstall: {
    name: 'block_macos_sonoma_app_install_darwin',
  },
  blockBluetooth: {
    name: 'block_bluetooth_file_exchange_darwin',
  },
  usbExternalStorageRestriction: {
    name: 'usb_storage_restriction_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  restrictiPhoneMirroringMac: {
    name: 'restrict_iphone_mirroring_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
  restrictAppleIntelligenceMac: {
    name: 'restrict_apple_intelligence_darwin',
    requirement: MdmRequirementsTagConsts.macOS,
  },
};

export const iOSPoliciesNamesConsts = {
  certInstallation: {
    name: 'certificates_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
  cloudPhoto: {
    name: 'disable_cloud_photo_library_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
  custom: {
    name: 'custom_mdm_profile_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
  disableAnalytics: {
    name: 'disable_analytics_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
  disableFacetime: {
    name: 'disable_facetime_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
  disableSiri: {
    name: 'disable_siri_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
  disableUnlockBiometrics: {
    name: 'disable_unlock_with_biometrics_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
  managedAppRestrictions: {
    name: 'managed_app_restrictions_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
  passcodeConfigurable: {
    name: 'passcode_configurable_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
  passcodeForUserEnrolledDevices: {
    name: 'require_passcode_for_user_enrolled_devices_ios',
    requirement: MdmRequirementsTagConsts.userEnrollmentMdm,
  },
  spotlight: {
    name: 'disable_spotlight_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
  systemUnlock: {
    name: 'disable_unlock_with_apple_watch_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
  webclips: {
    name: 'webclip_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
  wifi: {
    name: 'wifi_configuration_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
  disableEACS: {
    name: 'disableEACS_control_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
  appNotificationsios: {
    name: 'app_notifications_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
  supervisedRestrictions: {
    name: 'supervised_restrictions_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
  dnsHTTPS: {
    name: 'encrypted_dns_https_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
  dnsTLS: {
    name: 'encrypted_dns_tls_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
  airPrint: {
    name: 'airprint_policy_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
  customFont: {
    name: 'custom_font_policy_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
  activationLock: {
    name: 'activation_lock_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
  handleRapidSecurityResponse: {
    name: 'manage_rapid_security_response_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
  globalHttpProxy: {
    name: 'http_proxy_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
  scepProfile: {
    name: 'scep_profile_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
  blockApps: {
    name: 'block_apps_policy_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
  blockCustomApps: {
    name: 'block_custom_apps_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
  blockWidgetSharing: {
    name: 'block_iOS_widget_sharing_policy_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
  singleAppMode: {
    name: 'single_app_mode_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
  restrictiPhoneMirroring: {
    name: 'restrict_iphone_mirroring_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
  restrictAppleIntelligence: {
    name: 'restrict_apple_intelligence_ios',
    requirement: MdmRequirementsTagConsts.iOSMdm,
  },
};

export const GooglePolicyConsts = {
  policyTypes: {
    customPayload: 'Custom Payload',
    passcode: 'Passcode',
    runtimePermissions: 'Runtime Permissions',
  },

  policyActions: {
    enablePasscodePolicy: 'Enable Device Passcode Policy',
    enableWorkProfilePolicy: 'Enable Work Profile Passcode Policy',
  },
};

export const WindowsPoliciesNamesConsts = {
  certInstallation: {
    name: 'install_certificate_mdm_windows',
    requirement: MdmRequirementsTagConsts.windowOS,
  },
  blockBluetooth: {
    name: 'bluetooth_configuration_mdm_windows',
    requirement: MdmRequirementsTagConsts.windowOS,
  },
  vpnManagment: {
    name: 'vpn_v2_mdm_windows',
    requirement: MdmRequirementsTagConsts.windowOS,
  },
  wifiManagment: {
    name: 'wifi_configuration_mdm_windows',
    requirement: MdmRequirementsTagConsts.windowOS,
  },
  deviceLockManagment: {
    name: 'policy_max_inactivity_device_lock_mdm_windows',
    requirement: MdmRequirementsTagConsts.windowOS,
  },
  certificateScep: {
    name: 'scep_profiles_mdm_windows',
    requirement: MdmRequirementsTagConsts.windowOS,
  },
  configRefresh: {
    name: 'config_refresh_mdm_windows',
    requirement: MdmRequirementsTagConsts.windowOS,
  },
  customOmaUri: {
    name: 'custom_oma_uri_mdm_windows',
    requirement: MdmRequirementsTagConsts.windowOS,
  },
  appRestriction: {
    name: 'application_restriction_mdm_windows',
    requirement: MdmRequirementsTagConsts.windowOS,
  },
};

export const AuthnPolicyConsts = {
  policyActions: {
    allow: 'allow',
    allowWithMFA: 'allowwithmfa',
    deny: 'deny',
    legacy: 'legacy',
    unknown: 'unknown',
  },

  responseMessages: {
    org: {
      success: 'Conditional Access Policy Settings successfully saved.',
      failure: 'There was an error while saving Conditional Access Policy Settings.',
    },
    userportal: {
      success: 'User Portal Default Access Policy Settings successfully saved.',
      failure: 'There was an error while saving User Portal Default Access Policy Settings.',
    },
    sso: {
      success: 'SSO Applications Default Access Policy Settings successfully saved.',
      failure: 'There was an error while saving SSO Applications Default Access Policy Settings.',
    },
    ldap: {
      success: 'LDAP Applications Default Access Policy Settings successfully saved.',
      failure: 'There was an error while saving LDAP Default Access Policy Settings.',
    },
  },
};
