import { setupFeatureFlags } from '@jumpcloud/ui-feature-flags';

const flagConfigs = [
  {
    localName: 'abmConnector',
    sourceId: 'LD-2024-06-03-show-ABM-app-in-catalog',
  },
  {
    localName: 'addDeviceComponentLibraryUpdates',
    sourceId: 'LD-2024-03-29-add-device-page-update',
  },
  {
    localName: 'adpIdmConnector',
    sourceId: 'LD-2023-10-06-adp-internal-scim-import',
  },
  {
    localName: 'androidEmmFullyManaged',
    sourceId: '2023-05-12-android-emm-fullymanaged-dedicated',
  },
  {
    localName: 'androidEmmTenantBasedSubdomain',
    sourceId: 'LD-2024-08-23-android-emm-tenant-based-subdomain',
  },
  {
    localName: 'androidEmmZeroTouch',
    sourceId: '2023-06-21-android-emm-zero-touch',
  },
  {
    localName: 'browserPatchReport',
    sourceId: 'LD-2022-09-14-browser-patch-report',
  },
  {
    localName: 'deviceHealthMonitoring',
    sourceId: 'LD-2024-03-01-monitoring',
  },
  {
    localName: 'deviceListUpdates',
    sourceId: 'LD-2024-06-10-Device-List-Update',
  },
  {
    localName: 'deviceListUpdatesPhase2',
    sourceId: 'LD-2024-08-19-DeviceListPhase2',
  },
  {
    localName: 'deviceListFastFollow',
    sourceId: 'LD-2024-10-08-Device-List-Fast-Follow',
  },
  {
    localName: 'deviceLostMode',
    sourceId: 'LD-2024-04-02-enable-disable-lost-mode',
  },
  {
    localName: 'diEventsSearch',
    sourceId: '2022-12-19-di-events-search',
  },
  {
    localName: 'enhancedReportingUX',
    sourceId: 'LD-2024-08-23-enhanced-reporting-ux',
  },
  {
    localName: 'globalMfaEnforcementOptout',
    sourceId: 'LD-2024-07-02-global-mfa-enforcement-neworgs-optout',
  },
  {
    localName: 'primarySystemUserOnDevice',
    sourceId: 'HY-3131-primary-user-on-a-device-phase-1',
  },
  {
    localName: 'idmSupportCeridianDayforceHcm',
    sourceId: 'LD-2023-04-12-dayforce-hr-user-import',
    defaultValue: true,
  },
  {
    localName: 'importAgentConnectKeyView',
    sourceId: '2024-02-02-adi-import-agent-connect-key-view',
  },
  {
    localName: 'multipleIPsForRadius',
    sourceId: 'IN-2171-multiple-IPs-radius',
  },
  {
    localName: 'modernAdminAuthCAPs',
    sourceId: 'ZR-1041-modern-admin-auth-caps',
  },
  {
    localName: 'modernAdminAuthLinkedIdentities',
    sourceId: 'LD-2024-06-24-modern-admin-auth-linked-identities',
  },
  {
    localName: 'odsReports',
    sourceId: 'LD-2024-07-15-ods-reports',
  },
  {
    localName: 'osPatchMgmtPolicyReport',
    sourceId: 'LD-2022-06-27-os-patch-management-report',
  },
  {
    localName: 'passwordComplexity',
    sourceId: 'LD-2024-04-22-password-complexity',
  },
  {
    localName: 'passwordResetViaRecoveryEmail',
    sourceId: '2021-10-14-pwd-reset-via-recovery-email',
  },
  {
    localName: 'peripheraluiMFE',
    sourceId: '2024-05-22-peripheralui-mfe',
  },
  {
    localName: 'privateRepo',
    sourceId: '2023-05-11-private-repo',
  },
  {
    localName: 'remoteAssist',
    sourceId: 'LD-2022-08-01-remote-assist',
    defaultValue: true,
  },
  {
    localName: 'remoteAssistTenantBasedFlow',
    sourceId: 'BAN-2607-remote-access-eu-tenant-subdomain',
  },
  {
    localName: 'requestApprovalWorkflow',
    sourceId: 'LD-2024-07-15-request-approval-workflow',
  },
  {
    localName: 'serviceAccounts',
    sourceId: 'LD-2024-10-18-enable-service-accounts',
    defaultValue: true,
  },
  {
    localName: 'splitMfaStatusColumn',
    sourceId: '2024-02-27-iridium-mfa-enrollment-status-visible',
  },
  {
    localName: 'ssoReport',
    sourceId: 'LD-2022-04-13-sso-report',
  },
  {
    localName: 'synthesizedReports',
    sourceId: 'LD-2021-04-01-synthesized-reports',
  },
  {
    localName: 'TBTSurveyUpdates',
    sourceId: 'LD-2024-06-13-TBT-Survey',
  },
  {
    localName: 'updatedSubmitCase',
    sourceId: 'LD-2024-05-14-Updated-Submit-Case-AP',
  },
  {
    localName: 'usePaginationMethodForBoundDevices',
    sourceId: 'LD-2023-01-10-show-bound-devices-traversals',
  },
  {
    localName: 'userListUpdates',
    sourceId: 'LD-2024-10-21-user-list-updates',
  },
  {
    localName: 'usersToDevicesReport',
    sourceId: 'LD-2022-09-14-users-to-devices-report',
    defaultValue: true,
  },
  {
    localName: 'usersToDirectoriesReport',
    sourceId: 'LD-2022-09-14-users-to-directories-report',
    defaultValue: true,
  },
  {
    localName: 'usersToLdapServersReport',
    sourceId: 'LD-2022-09-14-users-to-ldap-servers-report',
    defaultValue: true,
  },
  {
    localName: 'usersToRadiusServersReport',
    sourceId: 'LD-2022-09-14-users-to-radius-servers-report',
    defaultValue: true,
  },
  {
    localName: 'usersToUserGroupsReport',
    sourceId: 'LD-2022-12-20-users-to-user-groups-report',
  },
  {
    localName: 'testFlag',
    sourceId: 'NE-attribute-test-flag',
  },
  {
    localName: 'nativeAppLifecycle',
    sourceId: 'NE-3043-native-app-lifecycle',
  },
  {
    localName: 'deviceMonitoringAlerting',
    sourceId: 'NE-3009-pnp-device-monitoring-alerting',
  },
];

/**
 * Maintaining this const for legacy reasons. We are attempting to move away from it,
 * but updating all the references to this all at once was too big an effort at this time.
 *
 * @deprecated Just reference the flag names directly. No need for a intermediary const
 */
export const featureFlags = flagConfigs.reduce((prevFlags, flag) => ({
  ...prevFlags,
  // Build a mapping where the localName is both key and value.
  [flag.localName]: flag.localName,
}), {});

export const {
  initializeFlags,
  useFeatureFlags,
  FeatureFlagsModule,
  FeatureFlagMixin,
} = setupFeatureFlags(flagConfigs);

/**
 * This function is being exported for legacy tests
 * It is not expected to be used outside of tests.
 * @deprecated
 */
export function createFeatureFlagsModule() {
  return setupFeatureFlags(flagConfigs).FeatureFlagsModule;
}

export default FeatureFlagsModule;
